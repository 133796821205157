<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Город:"
          label-for="city_id"
        >
          <ValidationProvider
            #default="{ errors }"
            name="city_id"
            vid="city_id"
          >
            <v-select
              v-model="metroData.city_id"
              label="name"
              :reduce="option => option.id"
              :options="citiesList"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Slug:"
          label-for="slug"
        >
          <ValidationProvider
            #default="{ errors }"
            name="slug"
            vid="slug"
          >
            <b-form-input
              id="slug"
              v-model="metroData.slug"
              autocomplete="off"
              placeholder="slug"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Название:"
          label-for="name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="name"
            vid="name"
          >
            <b-form-input
              id="name"
              v-model="metroData.name"
              autocomplete="off"
              placeholder="Название"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Название линии:"
          label-for="line_name"
        >
          <ValidationProvider
            #default="{ errors }"
            name="line_name"
            vid="line_name"
          >
            <b-form-input
              id="line_name"
              v-model="metroData.line_name"
              autocomplete="off"
              placeholder="Название линии"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Цвет линии:"
          label-for="line_color"
        >
          <ValidationProvider
            #default="{ errors }"
            name="line_color"
            vid="line_color"
          >
            <b-form-input
              id="line_color"
              v-model="metroData.line_color"
              autocomplete="off"
              placeholder="Цвет линии"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Широта:"
          label-for="longitude"
        >
          <ValidationProvider
            #default="{ errors }"
            name="longitude"
            vid="longitude"
          >
            <b-form-input
              id="longitude"
              v-model="metroData.longitude"
              autocomplete="off"
              placeholder="31.000"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Долгота:"
          label-for="latitude"
        >
          <ValidationProvider
            #default="{ errors }"
            name="latitude"
            vid="latitude"
          >
            <b-form-input
              id="latitude"
              v-model="metroData.latitude"
              autocomplete="off"
              placeholder="31.000"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmit"
        >
          {{ metroData.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  name: 'MetroForm',
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  props: {
    metroData: {
      type: Object,
      require: true,
      default: () => {},
    },
    citiesList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
