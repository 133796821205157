var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Город:","label-for":"city_id"}},[_c('ValidationProvider',{attrs:{"name":"city_id","vid":"city_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.citiesList},model:{value:(_vm.metroData.city_id),callback:function ($$v) {_vm.$set(_vm.metroData, "city_id", $$v)},expression:"metroData.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Slug:","label-for":"slug"}},[_c('ValidationProvider',{attrs:{"name":"slug","vid":"slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slug","autocomplete":"off","placeholder":"slug"},model:{value:(_vm.metroData.slug),callback:function ($$v) {_vm.$set(_vm.metroData, "slug", $$v)},expression:"metroData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Название:","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","autocomplete":"off","placeholder":"Название"},model:{value:(_vm.metroData.name),callback:function ($$v) {_vm.$set(_vm.metroData, "name", $$v)},expression:"metroData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Название линии:","label-for":"line_name"}},[_c('ValidationProvider',{attrs:{"name":"line_name","vid":"line_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"line_name","autocomplete":"off","placeholder":"Название линии"},model:{value:(_vm.metroData.line_name),callback:function ($$v) {_vm.$set(_vm.metroData, "line_name", $$v)},expression:"metroData.line_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Цвет линии:","label-for":"line_color"}},[_c('ValidationProvider',{attrs:{"name":"line_color","vid":"line_color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"line_color","autocomplete":"off","placeholder":"Цвет линии"},model:{value:(_vm.metroData.line_color),callback:function ($$v) {_vm.$set(_vm.metroData, "line_color", $$v)},expression:"metroData.line_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Широта:","label-for":"longitude"}},[_c('ValidationProvider',{attrs:{"name":"longitude","vid":"longitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"longitude","autocomplete":"off","placeholder":"31.000"},model:{value:(_vm.metroData.longitude),callback:function ($$v) {_vm.$set(_vm.metroData, "longitude", $$v)},expression:"metroData.longitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Долгота:","label-for":"latitude"}},[_c('ValidationProvider',{attrs:{"name":"latitude","vid":"latitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"latitude","autocomplete":"off","placeholder":"31.000"},model:{value:(_vm.metroData.latitude),callback:function ($$v) {_vm.$set(_vm.metroData, "latitude", $$v)},expression:"metroData.latitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.metroData.id ? 'Обновить и закрыть' : 'Создать и закрыть')+" ")])],1),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Отменить ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }